import styled from 'styled-components';

export const H2 = styled.h2`
  font-size: 25px;
  margin-bottom: 15px;
  letter-spacing: 1px;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: row;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const Image = styled.div`
  flex: 4;
  min-height: 400px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 576px) {
    display: none;
  }
`;

export const Article = styled.article`
  flex: 7;
  padding-right: 20px;
  text-align: justify;
  p {
    margin-bottom: 15px;
    line-height: 25px;
    letter-spacing: 0.5px;
  }
  ul {
    padding-left: 25px;
    li {
      margin-bottom: 15px;
    }
  }
`;

export const Signature = styled.div`
  font-family: 'Lainie';
  font-size: 30px;
  margin-top: 50px;
`;

export const Map = styled.div`
  flex: 7;
  min-height: 400px;
  @media (max-width: 576px) {
    display: none;
  }
`;
