import { createGlobalStyle } from 'styled-components';
import { Lainie } from './assets/fonts/lainie';

export default createGlobalStyle`

@font-face {
  font-family: 'Lainie';
  src: url(${Lainie.ttf}) format('truetype'),
    url(${Lainie.woff}) format('woff'),
    url(${Lainie.eot}) format('embedded-opentype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  background:#CCBE44 url("/images/background.jpg") no-repeat fixed center center;
  font-family: 'Raleway', sans-serif;
  color: #515151;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

#root {
}
`;
