import React from 'react';
import { Map as LeafletMap, Marker, Popup, TileLayer } from 'react-leaflet';
import { H2, Section, Article, Map } from '../styles';

import 'leaflet/dist/leaflet.css';

import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export const Contatti = () => {
  const position = [45.716488, 8.904104];
  const map = (
    <LeafletMap
      center={position}
      zoom={16}
      style={{ width: '100%', height: '100%' }}
      attributionControl={false}
    >
      <TileLayer
        url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
        maxZoom={20}
        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
      />
      <Marker position={position}>
        <Popup>
          <b>Impresa Edile Giardina</b>
          <br />
          Via A. Manzoni, 15
          <br />
          21049 - Tradate (VA)
        </Popup>
      </Marker>
    </LeafletMap>
  );

  return (
    <>
      <H2>Contatti</H2>
      <Section>
        <Article>
          <p>
            Siamo a tua disposizione per qualsiasi informazione o preventivo.
          </p>
          <p>
            <b>Impresa Edile Giardina</b>
            <br />
            Via A. Manzoni, 15
            <br />
            21049 Tradate (VA)
          </p>
          <p>
            Tel: <a href="tel:+393393484671">+39 339 3484671</a>
          </p>
          <p>
            Mail:{' '}
            <a href="mailto:info@impresagiardina.it">info@impresagiardina.it</a>
          </p>
        </Article>
        <Map>{map}</Map>
      </Section>
    </>
  );
};

export default Contatti;
