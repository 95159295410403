import React from 'react';
import { H2, Section, Article, Signature, Image } from '../styles';

export const Homepage = () => {
  return (
    <>
      <H2>Chi Siamo</H2>
      <Section>
        <Article>
          <p>
            L’impresa edile Giardina è una società con al suo fianco numerosi
            specialisti nel campo dell’edilizia.
          </p>
          <p>
            Un’azienda che ha puntato tutto sull’organizzazione e la qualità dei
            servizi.
          </p>
          <p>
            Ogni lavoro è coordinato e controllato dall’esperienza del titolare
            Giardina Vincenzo, che segue personalmente il cliente per soddisfare
            le specifiche esigenze.
          </p>
          <Signature>Vincenzo Giardina</Signature>
        </Article>
        <Image
          style={{ backgroundImage: 'url(/images/pages/homepage.jpg)' }}
        ></Image>
      </Section>
    </>
  );
};

export default Homepage;
