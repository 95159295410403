import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import { Header, H1, Nav, Main, Footer } from './styles';
import { Homepage, Manutenzioni, Servizi, Contatti } from '../';

export const App = () => {
  return (
    <>
      <Header>
        <H1>Impresa Edile Giardina</H1>
        <Nav>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/manutenzioni">Manutenzioni</NavLink>
          <NavLink to="/servizi">Servizi</NavLink>
          <NavLink to="/contatti">Contatti</NavLink>
        </Nav>
      </Header>
      <Main>
        <Switch>
          <Route path="/" exact component={Homepage}></Route>
          <Route path="/manutenzioni" exact component={Manutenzioni}></Route>
          <Route path="/servizi" exact component={Servizi}></Route>
          <Route path="/contatti" exact component={Contatti}></Route>
        </Switch>
      </Main>
      <Footer>
        Copyright &copy; {new Date().getFullYear()} impresagiardina.it - Via A.
        Manzoni 15 - 21049 Tradate (VA) - P.IVA 02201550122 - Created by{' '}
        <a
          href="http://www.mattiamalonni.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mattia Malonni
        </a>
      </Footer>
    </>
  );
};

export default App;
