import styled from 'styled-components';

export const Header = styled.header`
  background-color: rgba(255, 255, 255, 0.8);
  margin-top: 35px;
  padding: 15px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 576px) {
    display: block;
  }
`;

export const H1 = styled.h1`
  font-size: 30px;
`;

export const Nav = styled.nav`
  a + a {
    margin-left: 15px;
  }
  @media (max-width: 576px) {
    margin-top: 15px;
  }
`;

export const Main = styled.main`
  margin: 50px 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px 60px;
`;

export const Footer = styled.footer`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px 25px;
  font-size: 13px;
`;
