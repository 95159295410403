import React from 'react';
import { H2, Section, Article, Image } from '../styles';

export const Servizi = () => {
  return (
    <>
      <H2>Servizi</H2>
      <Section>
        <Article>
          <p>L’impresa edile Giardina Vincenzo esegue</p>
          <ul>
            <li>Demolizione</li>
            <li>Costruzioni</li>
            <li>Pavimenti e rivestimenti</li>
            <li>Opere in cartongesso-gesso</li>
            <li>Recupero sottotetti</li>
            <li>Ristrutturazione interni ed esterni</li>
            <li>Impianti idraulici, elettrici e di aria condizionata</li>
            <li>Riparazione e manutenzione</li>
            <li>Opere di tinteggiatura.</li>
          </ul>
        </Article>
        <Image
          style={{ backgroundImage: 'url(/images/pages/manutenzioni.jpg)' }}
        ></Image>
      </Section>
    </>
  );
};

export default Servizi;
