import React from 'react';
import { H2, Section, Article, Image } from '../styles';

export const Manutenzioni = () => {
  return (
    <>
      <H2>Manutenzioni</H2>
      <Section>
        <Article>
          <p>
            L’impresa Giardina grazie a squadre di specialisti si occupa di
            manutenzioni ordinarie e straordinarie effettuando ogni tipo di
            intervento, dalle semplici riparazioni alle lavorazioni più
            complesse.
          </p>
          <p>
            <b>PRONTO INTERVENTO</b>
          </p>
          <p>
            Si offre anche un servizio di pronto intervento per quanto riguarda
            le riparaioni urgenti coadiuvando il lavoro con quello di
            specialisti del settore che l’esigenza del momento richiede.
          </p>
        </Article>
        <Image
          style={{ backgroundImage: 'url(/images/pages/manutenzioni.jpg)' }}
        ></Image>
      </Section>
    </>
  );
};

export default Manutenzioni;
